<template>
    <b-card title="Accounts Receivable">
        <b-form>
            <b-input-group class="mt-2 mr-2">
                <b-input-group-prepend>
                    <div>
                        <vSelect :options="searchCritriaOption" label="name" v-model="searchCritria"
                            placeholder="Search ">
                        </vSelect>
                    </div>
                </b-input-group-prepend>
                <b-form-input :placeholder="Selected" @input="onInputChange"></b-form-input>
            </b-input-group>
        </b-form>
        <hr>
        <div>
            <!-- search input -->
            <div class="custom-search">
                <b-row>
                    <b-col md="3">
                       
                    </b-col>
                    <b-col md="3">
                        <!-- <b-button variant="primary">
                            <feather-icon icon="FileTextIcon" class="mr-50" />Export
                        </b-button> -->
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

            </div>

            <!-- table -->
            <vue-good-table :line-numbers="true" :columns="columns" :rows="rows" :rtl="direction" :search-options="{
                enabled: true,
                externalQuery: searchTerm
            }" :select-options="{
    enabled: false,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                <template slot="table-row" slot-scope="props">
                    <!-- Column: Name -->
                    

                    <!-- Column: Name -->
                    <span v-if="props.column.field === 'lastVisitDate'">

                        <span class="text-nowrap">
                            {{ convertTimeStampToDateLocal(props.row.lastVisitDate) }}
                        </span>
                       

                    </span>


                    <!-- Column: Name -->
                    <span v-else-if="props.column.field === 'name'">

                        <span class="text-nowrap">
                            {{ props.row.name }}
                        </span><br>
                        <span class="text-nowrap customerDetail">
                            [ {{ props.row.mobileNo }} ]
                        </span><br>
                        <span class="text-nowrap customerDetail">
                            {{ props.row.emailId }}
                        </span>

                    </span>


                    <!-- Column: Common -->
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>

                <!-- pagination -->
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap ">
                                Showing 1 to
                            </span>
                            <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']" class="mx-1"
                                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                            <span class="text-nowrap"> of {{ props.total }} entries </span>
                        </div>
                        <div>
                            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                                last-number align="right" prev-class="prev-item" next-class="next-item"
                                class="mt-1 mb-0" @input="(value) => props.pageChanged({ currentPage: value })">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </div>

    </b-card>
</template>
  
<script>
import {
    BSidebar, VBTooltip, VBToggle, BAvatar, BCard, BRow, BCol, BForm, BButton, BBadge, BPagination, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import api from '@/store/api'
import moment from 'moment'
import util from '@/store/utils'

export default {
    components: {
        VueGoodTable, BSidebar, VBToggle, BInputGroup, BInputGroupPrepend, vSelect,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem, BCard, BRow, BCol, BForm, BButton,
    },
    data() {
        return {
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Address',
                    field: 'address',
                },
                {
                    label: 'Last Visit',
                    field: 'lastVisitDate',
                },
                {
                    label: 'Wallet Balance',
                    field: 'walletBalance',
                },
                {
                    label: 'Amount Due',
                    field: 'amountDue',
                },
                {
                    label: 'Pending Orders',
                    field: 'pendingOrders',
                },
                {
                    label: 'Pic Ready To Dispatch',
                    field: 'picReadyToDispatch',
                },
                {
                    label: 'Pic Under Process',
                    field: 'picUnderProcess',
                },

            ],
            rows: [],
            searchTerm: '',
            searchCritriaOption: [],
            searchCritria: { 'filterType': 'mobileNo', 'name': 'MOBILE_NO' },
            Selected: 'Search Customer',
            searchBoxValue: '',
            customersList: [],

            customerDetail: {},
            customerPreference: '',
            communicationPreference: '',
            homeDelivery: '',
            registrationSourceOption: [],
            registrationSource: null,
            userId: 0,


        }
    },
    directives: {
        'b-toggle': VBToggle,
        'b-tooltip': VBTooltip,
        Ripple,
    },
    methods: {
        convertTimeStampToDateLocal(value) {
            if (value == null) {
                return '';
            } else {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            }
        },
        convertImage(photo) {
            if (photo == null) {
                return require('@/assets/images/avatars/placeholderImage.png');
            } else {
                return 'data:image/*;base64,' + photo;
            }

        },
        getCustomerList(text) {
            let self = this;
            var axios = require('axios');
            var data = '';
            let url='';
            if(text==''){
                url='/finance/accounts/getAccountsReceivableCustomersDetails/filter/' + null + ',' + ' ';
            }else{
                url='/finance/accounts/getAccountsReceivableCustomersDetails/filter/' + this.searchCritria.filterType + ',' + text
            }
            axios(api.getApi('get', url, data))
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    if (response.data != '') {
                        self.rows = response.data;
                    } else {
                        self.rows = []
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        changePassword(id) {
            this.userId = id;
        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            this.getCustomerList(text);
        },
        edit(id) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/customers/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    if (response.data.customerPreference == null) {
                        self.customerPreference = null;
                    } else {
                        self.customerPreference = response.data.customerPreference.name;
                    }
                    if (response.data.communicationPrefrence == null) {
                        self.communicationPreference = null;
                    } else {
                        self.communicationPreference = response.data.communicationPrefrence.name;
                    }
                    if (response.data.enabelHomeDelevery == true) {
                        self.homeDelivery = 'Yes';
                    } else {
                        self.homeDelivery = 'No';
                    }
                    if (response.data.registrationSource > 0) {
                        const object1 = self.registrationSourceOption.find(obj => obj.id == response.data.registrationSource);
                        self.registrationSource = { 'id': object1.id, 'name': object1.name };
                    }
                    if (self.registrationSource == 0 || self.registrationSource == null) {
                        self.registrationSource = 0;
                    }

                    let customerNameId = 0;
                    if (util.digits_count(response.data.id) == 3) {
                        customerNameId = "0" + response.data.id;
                    } else if (util.digits_count(response.data.id) == 2) {
                        customerNameId = "00" + response.data.id;
                    }
                    else if (util.digits_count(response.data.id) == 1) {
                        customerNameId = "000" + response.data.id;
                    }

                    self.customerDetail = {
                        'id': response.data.id,
                        'customerNameId': customerNameId,
                        'namePrefix': response.data.namePrefix,
                        'name': response.data.name,
                        'mobileNo': response.data.mobileNo,
                        'emailId': response.data.emailId,
                        'address': response.data.address,
                        'areaLocation': response.data.areaLocation,
                        'state': response.data.state,
                        'pinCode': response.data.pinCode,
                        'customerPreference': self.customerPreference,
                        'defaultDiscountPercentage': response.data.defaultDiscountPercentage,
                        'communicationPrefrence': self.communicationPreference,
                        'gstin': response.data.gstin,
                        'membershipId': response.data.membershipId,
                        'barcode': response.data.barcode,
                        'birthday': response.data.birthday,
                        'anniversary': response.data.anniversary,
                        'remarks': response.data.remarks,
                        'enabelHomeDelevery': self.homeDelivery,
                        'marketingSource': response.data.marketingSource,
                        'alternetPhone': response.data.alternetPhone,
                        'facebookId': response.data.facebookId,
                        'registrationSource': self.registrationSource,
                        'msgOnSearchCustomer': response.data.msgOnSearchCustomer,
                        'messageOnGarmentDelivery': response.data.msgOnGarmentDelevery,
                        'isEnebled': response.data.isEnebled
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getSearchFiltersList() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getSearchFiltersList', data))
                .then(function (response) {
                    for (let index = 0; index < response.data.length; index++) {
                        if (response.data[index] == 'ALL') {
                            self.searchCritriaOption.push({ 'filterType': 'all', 'name': response.data[index] })
                        } else if (response.data[index] == 'NAME') {
                            self.searchCritriaOption.push({ 'filterType': 'name', 'name': response.data[index] })
                        } else if (response.data[index] == 'ADDRESS') {
                            self.searchCritriaOption.push({ 'filterType': 'address', 'name': response.data[index] })
                        } else if (response.data[index] == 'MOBILE_NO') {
                            self.searchCritriaOption.push({ 'filterType': 'mobileNo', 'name': response.data[index] })
                        } else if (response.data[index] == 'EMAIL_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'emailId', 'name': response.data[index] })
                        } else if (response.data[index] == 'MEMBERSHIP_ID') {
                            self.searchCritriaOption.push({ 'filterType': 'membershipId', 'name': response.data[index] })
                        }

                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
        getRegistrationSource() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/getRegistrationSourceList', data))
                .then(function (response) {
                    let i = 0;
                    for (let index = 0; index < response.data.length; index++) {
                        i++;
                        self.registrationSourceOption.push({ 'id': i, 'name': response.data[index] })
                    }
                })
                .catch(function (error) {
                    console.log(error);

                });
        },
    },
    computed: {

    },
    created() {
        this.getSearchFiltersList();
        this.getRegistrationSource();
        this.getCustomerList('');
    },
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>

<style>
.imageIcons {
    margin-top: -25px;
    position: relative;
    z-index: 1;

}

.bloodDrop {
    color: red;
    width: 16px;
    height: 16px;
    stroke-width: 5;
}

.bloodGroup {
    color: #000;
    font-weight: bolder;
}

.isActive {
    color: green;
    width: 18px;
    height: 18px;
    stroke-width: 5;
}

.homeDiscountIcon {
    color: red;
    width: 14px;
    height: 14px;
    stroke-width: 4;
}

.isInActive {
    color: gray;
    width: 18px;
    height: 18px;
    stroke-width: 5;
}

.customerDetail {
    font-size: 12px;
}

.inline {
    padding: 1px;
    display: inline;
}
</style>